ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(59, 57, 57);
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 15px 15px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: rgb(192, 83, 83);
}

.grid.typography.required:before{
  content:"*";
  color: red;
  font-weight:bold;
}

.inputWithButton {
  position: relative;
  width : 150px;
}

.inputWithButton input{
    width: 70%;
    height: 25px;
    padding-right: 45px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top : 5px;
}